import React, { useMemo, useEffect } from 'react'

import styles from './style.module.scss'

import { useOwnerProperties, useDeleteProperty } from '../hooks'

import { get } from 'lodash-es'

import Loader from '../loader'

import { IoIosEye, IoMdTrash, IoMdAdd } from 'react-icons/io'

import cn from 'classnames'

import Button from '../button'

import { DashboardBreadcrumb } from '../breadcrumb'

import { Link } from 'gatsby'

function getPropertyStatus(status) {
    switch (status) {
        case 'draft':
            return 'In review'
        case 'publish':
            return 'Approved'
        default:
            return 'N/A'
    }
}

export default () => {
    const { response, loading, submit: loadProperties } = useOwnerProperties()

    const {
        submit: deleteProperty,
        response: deleteResponse,
        statusCode: deleteStatusCode,
    } = useDeleteProperty()

    const properties = useMemo(() => get(response, 'data') || [], [response])

    const onDeleteProperty = ({ id }) => {
        if (
            window.confirm(
                'Are you sure you want to delete this property? This cannot be undone.'
            )
        ) {
            deleteProperty({ id })
        }
    }

    useEffect(() => {
        if (deleteStatusCode === 200) {
            loadProperties()
        }
    }, [deleteStatusCode, deleteResponse])

    return (
        <div className={styles.wrapper}>
            {loading && <Loader />}
            <DashboardBreadcrumb
                breadcrumb={[
                    {
                        title: 'Dashboard',
                        link: '/owner-dashboard',
                    },
                    {
                        title: 'Properties',
                    },
                ]}
            />
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1 className={styles.pageTitle}>Properties</h1>
                    <Link className={styles.add} to="/owner-property">
                        <IoMdAdd />
                    </Link>
                </div>
                {!properties.length && (
                    <div className={styles.noPropertiesCouldBeFound}>
                        <span>
                            No properties could be found, go ahead and add your
                            first property.
                        </span>
                        <Button link="/owner-property">
                            Add your first property
                        </Button>
                    </div>
                )}
                <div className={cn('row', styles.propertiesRow)}>
                    {properties.map((property) => (
                        <div
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            key={property.id}
                        >
                            <div className={styles.property}>
                                <div className={styles.propertyImage}>
                                    <img src={property.featured_image} />
                                </div>
                                <div className={styles.propertyBody}>
                                    <div className={styles.propertyTitle}>
                                        {property.title}
                                    </div>
                                </div>

                                <div className={styles.propertyFooter}>
                                    <div
                                        className={cn(
                                            styles.propertyStatus,
                                            styles[property.post_status]
                                        )}
                                    >
                                        {getPropertyStatus(
                                            property.post_status
                                        )}
                                    </div>
                                    <div className={styles.actions}>
                                        <div
                                            className={styles.action}
                                            onClick={() => {
                                                if (
                                                    property.preview_available
                                                ) {
                                                    window.open(
                                                        '/property/' +
                                                            property.slug
                                                    )
                                                } else {
                                                    alert(
                                                        'Preview will be available after 24 hours of approval.'
                                                    )
                                                }
                                            }}
                                        >
                                            <IoIosEye />
                                        </div>
                                        <div
                                            onClick={() =>
                                                onDeleteProperty({
                                                    id: property.id,
                                                })
                                            }
                                            className={cn(
                                                styles.action,
                                                styles.delete
                                            )}
                                        >
                                            <IoMdTrash />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
